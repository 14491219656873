// Plugins globaux

// jQuery
const $ = require('jquery');
window.jQuery = $;
window.$ = $;

// jQuery UI
require('webpack-jquery-ui/autocomplete');
require('webpack-jquery-ui/draggable');
require('webpack-jquery-ui/droppable');
require('webpack-jquery-ui/datepicker');
require('jquery-ui/themes/base/theme.css');
require(`jquery-ui/ui/i18n/datepicker-${cms_lang}.js`);

// TODO : modifier les .load() en .on('load') -> La ligne suivante est un patch
jQuery.fn.load = function(callback){ $(window).on("load", callback) };
